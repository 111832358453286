import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Header from "../components/Header"

export default ({data}) => {
	const posts = data.allMarkdownRemark.nodes;
	return (
		<Layout>
			<SEO pageTitle="Blog" />
			<Header pageTitle="Blog" bgImage="backgrounds/header.jpg" />
			<section className="intro-text">
				<div className="container">
					Intro text for blog. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mauris pharetra et ultrices neque ornare aenean euismod elementum nisi. Cursus sit amet dictum sit.
				</div>
			</section>
			<section className="blog list grey-bg">
				<ol className="container">
				{posts.map(post => {
					return (
						<li key={post.fields.slug}>
							<article>
								<Link to={'/blog' + post.fields.slug}><h2>{post.frontmatter.title}</h2></Link>
								<div className="date">{post.frontmatter.date}</div>
								<p dangerouslySetInnerHTML={{__html: post.excerpt}}/>
								<Link className="button info" to={'/blog' + post.fields.slug} aria-label={"Read more about " + post.frontmatter.title}>Read more</Link>
							</article>
						</li>
					)
				})}
				</ol>
			</section>
		</Layout>
	)
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt(pruneLength: 250)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
        }
      }
    }
  }
`